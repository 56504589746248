import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);
};

const App = () => {
  const [homePrice, setHomePrice] = useState(250000);
  const [downPaymentType, setDownPaymentType] = useState('percentage');
  const [downPaymentPercent, setDownPaymentPercent] = useState(10);
  const [downPaymentAmount, setDownPaymentAmount] = useState(25000);
  const [interestRate, setInterestRate] = useState(3.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [pmiRate, setPmiRate] = useState(0.5);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [schedule, setSchedule] = useState([]);
  const [zoomMonths, setZoomMonths] = useState(60);  // Default to 5 years
  const [showResults, setShowResults] = useState(false);

  const calculateLoanAmount = () => {
    if (downPaymentType === 'percentage') {
      return homePrice * (1 - (downPaymentPercent || 0) / 100);
    } else {
      return homePrice - (downPaymentAmount || 0);
    }
  };

  const calculateMortgage = () => {
    const loanAmount = calculateLoanAmount();
    const monthlyRate = (interestRate || 0) / 100 / 12;
    const numberOfPayments = (loanTerm || 0) * 12;
    const monthlyPmiRate = (pmiRate || 0) / 100 / 12;
    
    if (loanAmount <= 0 || monthlyRate <= 0 || numberOfPayments <= 0) {
      setMonthlyPayment(0);
      setSchedule([]);
      return;
    }

    const monthlyPaymentWithoutPmi = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    let balance = loanAmount;
    const newSchedule = [];

    for (let i = 1; i <= numberOfPayments; i++) {
      const interest = balance * monthlyRate;
      const principalPayment = monthlyPaymentWithoutPmi - interest;
      balance -= principalPayment;

      const loanToValue = balance / homePrice * 100;
      const pmi = loanToValue > 80 ? balance * monthlyPmiRate : 0;
      const totalPayment = monthlyPaymentWithoutPmi + pmi;

      newSchedule.push({
        month: i,
        payment: totalPayment,
        principal: principalPayment,
        interest: interest,
        balance: balance > 0 ? balance : 0,
        pmi: pmi
      });
    }

    setMonthlyPayment(newSchedule[0].payment);
    setSchedule(newSchedule);
    setShowResults(true);
  };

  useEffect(() => {
    setShowResults(false);
  }, [homePrice, downPaymentType, downPaymentPercent, downPaymentAmount, interestRate, loanTerm, pmiRate]);

  const handleNumberChange = (setter) => (e) => {
    const value = e.target.value;
    setter(value === '' ? '' : Number(value));
  };

  const handleDownPaymentChange = (e) => {
    const value = e.target.value;
    if (downPaymentType === 'percentage') {
      setDownPaymentPercent(value === '' ? '' : Number(value));
      setDownPaymentAmount(value === '' ? '' : homePrice * (Number(value) / 100));
    } else {
      setDownPaymentAmount(value === '' ? '' : Number(value));
      setDownPaymentPercent(value === '' ? '' : (Number(value) / homePrice) * 100);
    }
  };

  const handleZoomChange = (e) => {
    setZoomMonths(Number(e.target.value));
  };

  return (
    <div className="mortgage-calculator">
      <div className="thin-banner">
        <div className="profile-image">
          <img 
            src="https://www.brisbaneheadshots.com.au/images/galleries/men-corporate/lifestyle-photography.jpg" 
            alt="Profile" 
            style={{
              objectFit: 'cover',
              objectPosition: 'left 0%',
              width: '250%',
              height: '250%',
              transform: 'translateY(-8%) translateX(-50%)'
            }}
          />
        </div>
        <div className="contact-info">
          <span className="name">John Smith</span>
          <span className="separator">|</span>
          <span className="phone"><a href="tel:+15551234567">(555) 123-4567</a></span>
          <span className="separator">|</span>
          <span className="email"><a href="mailto:john.doe@example.com">john.smith@example.com</a></span>
          <span className="separator">|</span>
          <span className="company">ABC Mortgage Solutions</span>
          <span className="separator">|</span>
          <span className="website"><a href="https://www.example.com">example.com</a></span>
        </div>
      </div>
      <h1 className="title">Mortgage Calculator</h1>
      <div className="input-grid">
        <div className="input-group">
          <label htmlFor="homePrice">Home Price ($)</label>
          <input
            id="homePrice"
            type="number"
            value={homePrice}
            onChange={handleNumberChange(setHomePrice)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="downPaymentType">Down Payment Type</label>
          <select
            id="downPaymentType"
            value={downPaymentType}
            onChange={(e) => setDownPaymentType(e.target.value)}
          >
            <option value="percentage">Percentage</option>
            <option value="amount">Amount</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="downPayment">
            Down Payment ({downPaymentType === 'percentage' ? '%' : '$'})
          </label>
          <input
            id="downPayment"
            type="number"
            value={downPaymentType === 'percentage' ? downPaymentPercent : downPaymentAmount}
            onChange={handleDownPaymentChange}
          />
        </div>
        <div className="input-group">
          <label htmlFor="interestRate">Interest Rate (%)</label>
          <input
            id="interestRate"
            type="number"
            step="0.1"
            value={interestRate}
            onChange={handleNumberChange(setInterestRate)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="loanTerm">Loan Term (years)</label>
          <input
            id="loanTerm"
            type="number"
            value={loanTerm}
            onChange={handleNumberChange(setLoanTerm)}
          />
        </div>
        <div className="input-group">
          <label htmlFor="pmiRate">PMI Rate (%)</label>
          <input
            id="pmiRate"
            type="number"
            step="0.01"
            value={pmiRate}
            onChange={handleNumberChange(setPmiRate)}
          />
        </div>
      </div>
      <button onClick={calculateMortgage} className="calculate-btn">Calculate</button>
      {showResults && (
        <>
          <div className="results">
            <h2>Monthly Payment: <span className="highlight">${formatNumber(monthlyPayment)}</span></h2>
            <p>Loan Amount: <span className="highlight">${formatNumber(calculateLoanAmount())}</span></p>
          </div>
          <div className="zoom-control">
            <label htmlFor="zoomSlider">Zoom: {zoomMonths} months</label>
            <input
              id="zoomSlider"
              type="range"
              min="12"
              max={loanTerm * 12}
              value={zoomMonths}
              onChange={handleZoomChange}
              className="slider"
            />
          </div>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={schedule.slice(0, zoomMonths)}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={(value) => `$${formatNumber(value)}`} />
                <Tooltip 
                  formatter={(value, name) => [`$${formatNumber(value)}`, name]}
                  labelFormatter={(label) => `Month ${label}`}
                />
                <Legend />
                <Line type="monotone" dataKey="balance" name="Balance" stroke="#8884d8" />
                <Line type="monotone" dataKey="principal" name="Principal" stroke="#82ca9d" />
                <Line type="monotone" dataKey="interest" name="Interest" stroke="#ffc658" />
                <Line type="monotone" dataKey="pmi" name="PMI" stroke="#ff7300" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Payment</th>
                  <th>Principal</th>
                  <th>Interest</th>
                  <th>PMI</th>
                  <th>Remaining Balance</th>
                </tr>
              </thead>
              <tbody>
                {schedule.slice(0, zoomMonths).map((row) => (
                  <tr key={row.month}>
                    <td>{row.month}</td>
                    <td>${formatNumber(row.payment)}</td>
                    <td>${formatNumber(row.principal)}</td>
                    <td>${formatNumber(row.interest)}</td>
                    <td>${formatNumber(row.pmi)}</td>
                    <td>${formatNumber(row.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <div className="disclaimer">
        <p>* PMI (Private Mortgage Insurance) is typically required when the down payment is less than 20% of the home's value. PMI will be automatically removed from the calculation once the loan-to-value ratio reaches 80% (i.e., when the buyer's equity reaches 20% or more).</p>
      </div>
      <style jsx>{`
        .mortgage-calculator {
          font-family: Arial, sans-serif;
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          background-color: #f0f4f8;
          border-radius: 10px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .thin-banner {
          display: flex;
          align-items: center;
          background-color: #f8f9fa;
          padding: 10px 20px;
          font-size: 14px;
          border-bottom: 1px solid #e9ecef;
          white-space: nowrap;
          overflow-x: auto;
          margin: -20px -20px 20px -20px;
        }
        .profile-image {
          width: 40px;
          height: 40px;
          min-width: 40px;
          overflow: hidden;
          border-radius: 50%;
          margin-right: 20px;
        }
        .profile-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .contact-info {
          display: flex;
          align-items: center;
          flex-grow: 1;
        }
        .contact-info span {
          margin-right: 8px;
        }
        .name {
          font-weight: bold;
        }
        .separator {
          color: #ced4da;
          margin: 0 8px;
        }

        .title {
          color: #2c3e50;
          text-align: center;
          margin-bottom: 20px;
        }

        .input-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 20px;
          margin-bottom: 20px;
        }

        .input-group {
          display: flex;
          flex-direction: column;
        }

        label {
          margin-bottom: 5px;
          color: #34495e;
          font-weight: bold;
        }

        input, select {
          padding: 10px;
          border: 1px solid #bdc3c7;
          border-radius: 5px;
          font-size: 16px;
        }

        .calculate-btn {
          background-color: #3498db;
          color: white;
          border: none;
          padding: 10px 20px;
          font-size: 18px;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.3s;
        }

        .calculate-btn:hover {
          background-color: #2980b9;
        }

        .results {
          background-color: #ecf0f1;
          padding: 20px;
          border-radius: 5px;
          margin-top: 20px;
        }

        .highlight {
          color: #e74c3c;
          font-weight: bold;
        }

        .zoom-control {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .slider {
          width: 100%;
          margin-top: 10px;
        }

        .chart-container {
          margin-top: 30px;
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .table-container {
          margin-top: 30px;
          overflow-x: auto;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          background-color: white;
        }

        th, td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }

        th {
          background-color: #3498db;
          color: white;
        }

        tr:nth-child(even) {
          background-color: #f2f2f2;
        }

        .disclaimer {
          margin-top: 20px;
          font-size: 14px;
          color: #7f8c8d;
        }

        @media (max-width: 768px) {
          .input-grid {
            grid-template-columns: 1fr;
          }
          .thin-banner {
            font-size: 12px;
            padding: 8px 15px;
          }
          .profile-image {
            width: 30px;
            height: 30px;
            min-width: 30px;
            margin-right: 15px;
          }
          .calculate-btn {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default App;
